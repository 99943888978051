import React, { useEffect, useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import useOnclickOutside from "react-cool-onclickoutside";

const getDistanceMatrix = async (address) => {
  return new Promise(function (resolve, reject) {
    if (window !== undefined) {
      if (window.google !== undefined) {
        // Karlspltaz
        // var origin1 = new google.maps.LatLng(48.1977694, 16.3702998);
        // Jurekgasse 4
        var origin1 = new window.google.maps.LatLng(48.1923829, 16.3294467);

        // Jurekgasse 4 : 48.1923829, 16.3294467
        var destinationA = address;

        var service = new window.google.maps.DistanceMatrixService();

        service.getDistanceMatrix(
          {
            origins: [origin1],
            destinations: [destinationA],
            travelMode: "DRIVING",
            drivingOptions: {
              departureTime: new Date(Date.now() + 100000),
              trafficModel: "pessimistic",
            },
          },
          function (results, status) {
            if (status !== "OK") {
              reject(status);
            }
            resolve(results);
          }
        );
      } else {
        reject("window not defined");
      }
    } else {
      reject("window not defined");
    }
  });
};

const PlacesAutocomplete = ({ onChange }) => {
  const [currIndex, setCurrIndex] = useState(null);
  const [error, setError] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ["at"] },
      types: ["address"],
    },
    debounce: 300,
  });
  let cachedVal = "";
  const hasSuggestions = status === "OK";
  const acceptedKeys = ["ArrowUp", "ArrowDown", "Escape", "Enter"];

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    onChange(null);
    //dismissSuggestions();
  });

  const getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] === "locality") {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  const getStreet = (addressArray) => {
    let streetaddress = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] === "route") {
        streetaddress = addressArray[i].long_name;
        return streetaddress;
      }
    }
  };
  const getstreetNumber = (addressArray) => {
    let street_number = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] === "street_number") {
        street_number = addressArray[i].long_name;
        return street_number;
      }
    }
  };
  const getPostalcode = (addressArray) => {
    let code = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] === "postal_code") {
        code = addressArray[i].long_name;
        return code;
      }
    }
  };

  const dismissSuggestions = () => {
    setCurrIndex(null);
    clearSuggestions();
  };
  const handleKeyDown = (e) => {
    let nextIndex = null;
    if (!hasSuggestions || !acceptedKeys.includes(e.key)) return;

    if (e.key === "Enter" || e.key === "Escape") {
      e.preventDefault();
      setInput(currIndex ? data[currIndex] : data[0], false)
        .then((res) => {
          const tmpObj = JSON.parse(JSON.stringify(res));
          getGeocode({ address: res.destinationAddresses[0] })
            .then((response) => {
              const tmp = response[0].address_components;
              tmpObj.city = getCity(tmp);
              tmpObj.street = getStreet(tmp);
              tmpObj.zip = getPostalcode(tmp);
              tmpObj.streetNumber = getstreetNumber(tmp);
              const { lat, lng } = getLatLng(response[0]);
              tmpObj.lat = lat;
              tmpObj.lng = lng;
              onChange(tmpObj);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
    if (e.key === "ArrowUp") {
      e.preventDefault();
      nextIndex = currIndex ?? data?.length;
      nextIndex = nextIndex > 0 ? nextIndex - 1 : null;
    } else {
      nextIndex = currIndex ?? -1;
      nextIndex = nextIndex < data?.length - 1 ? nextIndex + 1 : null;
    }
    if (currIndex < data?.length - 1) {
      setCurrIndex(nextIndex);
      setInput(data[nextIndex], false)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleInput = (e) => {
    setValue(e.target.value);
    cachedVal = e.target.value;
  };

  const handleSelect = (suggestion) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"

    // Check if a number is present in the street address

    setInput(suggestion)
      .then((res) => {
        const tmpObj = JSON.parse(JSON.stringify(res));
        getGeocode({ placeId: suggestion.place_id })
          .then((response) => {
            const tmp = response[0].address_components;
            tmpObj.city = getCity(tmp);
            tmpObj.street = getStreet(tmp);
            tmpObj.zip = getPostalcode(tmp);
            tmpObj.streetNumber = getstreetNumber(tmp);
            const { lat, lng } = getLatLng(response[0]);
            tmpObj.lat = lat;
            tmpObj.lng = lng;
            onChange(tmpObj);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setInput = async (suggestion) => {
    return new Promise((resolve, reject) => {
      if (!suggestion) reject("woopsie");
      const {
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      const re = new RegExp("[0-9]");
      const hasStreetNumber = re.exec(main_text);
      const city_only = secondary_text.split(",", 1);
      const elem = document.getElementById("autocompleteinput");
      if (!hasStreetNumber) {
        setValue(`${main_text} `, false);
        elem.focus();
        setError("Gib Deine Hausnummer an");
        reject("no number");
      } else {
        const description = `${main_text} ${city_only}`;
        setValue(description, false);
        getDistanceMatrix(description)
          .then((res) => {
            setError(null);
            dismissSuggestions();

            elem.blur();
            resolve(res);
          })
          .catch((err) => {
            setError("Pas possible de trouver cette adresse");
            reject(err);
          });
      }
    });
  };
  const handleEnter = (idx) => () => {
    setCurrIndex(idx);
  };
  const handleLeave = () => {
    setCurrIndex(null);
  };
  const renderSuggestions = () =>
    data?.map((suggestion, idx) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      const city_only = secondary_text.split(",", 1);

      return (
        <li
          key={place_id}
          onClick={handleSelect(suggestion)}
          onMouseEnter={handleEnter(idx)}
          className={
            idx !== currIndex
              ? ["Demo__suggestion-item", "Demo__suggestion-item--active"]
              : "Demo__suggestion-item"
          }
          role="option"
          aria-selected={idx === currIndex}
        >
          <strong>{main_text}</strong> <small>{city_only}</small>
        </li>
      );
    });

  return (
    <div className="Demo__search-bar-container w-100 d-flex">
      <div className="Demo__search-input-container">
        <div ref={ref}>
          <input
            className="Demo__search-input"
            value={value}
            onChange={handleInput}
            onKeyDown={handleKeyDown}
            disabled={!ready}
            placeholder="Deine Strasse und Hausnummer"
            type="text"
            spellCheck="false"
            aria-autocomplete="list"
            aria-controls="ex-list-box"
            aria-activedescendant={
              currIndex !== null ? `ex-list-item-${currIndex}` : undefined
            }
            id="autocompleteinput"
            onKeyPress={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
          />
          {/* We can use the "status" to decide whether we should display the dropdown or not */}
          {hasSuggestions && showSuggestions && (
            <div className="Demo__autocomplete-container ">
              <ul
                className="list-unstyled"
                onMouseLeave={handleLeave}
                role="listbox"
              >
                {renderSuggestions()}{" "}
              </ul>
            </div>
          )}
          {error && <p>{error}</p>}
        </div>
      </div>
      {/* <div className="Button-container d-none d-sm-block">
        <button className="Suchen" type="button" onClick={() => handleKeyDown((e.key = "Enter"))}>
          Suchen
        </button>
      </div> */}
    </div>
  );
};

export default PlacesAutocomplete;
