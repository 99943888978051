import { Field, Form, Formik } from "formik";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { object, string } from "yup";

import Cart from "../components/Cart";
import { CartContext } from "../cartContext";
import { FormContext } from "../formContext";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import { navigate } from "gatsby";

const Address = ({ showAutoComplete, sForm }) => {
  return (
    <div
      className="registeredaddress d-flex align-items-center justify-content-center py-2"
      onClick={showAutoComplete}
    >
      <span className="font-size-xl d-none d-sm-block"> {sForm?.address}</span>
      <span className="font-size-lg d-block d-sm-none"> {sForm?.address}</span>
    </div>
  );
};

function Checkout01() {
  const { countCart } = useContext(CartContext);
  let initialValues = {
    fullname: "",
    email: "",
    phone: "",
    deliverydate: "",
    deliverytime: "",
    deliverytimeEnd: "",
    stiege: "",
    tur: "",
    stock: "",
    cart: "",
    formattedCart: "",
    paymentID: "",
    distance: 0,
    single: 0,
    double: 0,
    address: "",
    lat: 0,
    lng: 0,
    codepromo: "",
    firma: false,
  };
  const { checkout, saveForm } = useContext(FormContext);
  const [sForm, setsForm] = useState(initialValues);
  const [showAutoComplete, setShowAutoComplete] = useState(true);

  const subscriptionSchema = object().shape({
    stiege: string().max(4, "invalid"),
    stock: string().max(4, "invalid"),
    tur: string().max(4, "invalid"),
    address: string().required("Adress fehlt"),
    codepromo: string(),
  });
  let totalCart = countCart().totalCart;

  const handleAutoCompleteChange = (response) => {
    console.log(response);
    if (response !== null) {
      var origins = response.originAddresses;
      var destinations = response.destinationAddresses;
      let minDeliverySingle = 0;
      let minDeliveryDouble = 0;
      let distanceKm = "";
      let durationText = "";
      for (var i = 0; i < origins.length; i++) {
        var results = response.rows[i].elements;
        for (var j = 0; j < results.length; j++) {
          var element = results[j];
          var distance = element.distance.value;
          distanceKm = element.distance.text;
          durationText = element.duration_in_traffic.text;
          var duration = element.duration_in_traffic.value;
          const handlingTime = 15;
          const totalDuration = (duration / 60) * 2 + handlingTime; // Aller retour + 15 min
          const totalDistanceKm = (distance * 2) / 1000;
          const rateDriverPerHour = 20;
          const costDriver = (rateDriverPerHour / 60) * totalDuration;
          const costGaz = (1.2 / 10) * totalDistanceKm;
          console.log(costDriver);
          const costTotal =
            (costDriver + costGaz) *
            ((totalDistanceKm + totalDistanceKm * 1.07) / totalDistanceKm);
          minDeliverySingle = costTotal;
          minDeliveryDouble = costTotal * 2;
        }
      }

      let newObject = JSON.parse(JSON.stringify(sForm));
      newObject.single = minDeliverySingle;
      newObject.double = minDeliveryDouble;
      newObject.street = response.street;
      newObject.streetNumber = response.streetNumber;
      newObject.city = response.city;
      newObject.zip = response.zip;
      newObject.lat = response.lat;
      newObject.lng = response.lng;
      newObject.distance = distanceKm;
      newObject.duration = durationText;

      let tmpAdr = destinations[0].split(",", 2);
      newObject.address = tmpAdr.join(" ");
      saveForm(newObject);
    } else {
      setShowAutoComplete(false);
    }
  };

  useEffect(() => {
    if (checkout.length > 0) {
      setsForm(checkout[0]);
    }
    return () => {};
  }, [checkout]);

  useEffect(() => {
    sForm.address === ""
      ? setShowAutoComplete(true)
      : setShowAutoComplete(false);
    return () => {};
  }, [sForm]);

  if (!true)
    return (
      <section>
        <div
          className="container p-4"
          style={{ backgroundColor: "white", padding: 0 }}
        >
          <div className="flex align-items-center text-center">
            <h1 className="align-items-center">
              Wieder-Eröffnung am 01.10.2021
            </h1>
          </div>
        </div>
      </section>
    );

  return (
    <Fragment>
      <Formik
        initialValues={sForm}
        enableReinitialize
        mapPropsToErrors
        validationSchema={subscriptionSchema}
        onSubmit={(values) => {
          let newObject = JSON.parse(JSON.stringify(sForm));
          newObject["stiege"] = values.stiege;
          newObject["stock"] = values.stock;
          newObject["tur"] = values.tur;
          newObject["codepromo"] = values.codepromo;
          saveForm(newObject);
          navigate("/Checkout02");
        }}
      >
        {(formik, isSubmitting) => {
          return (
            <Form
              name="Checkout01"
              className="needs-validation"
              netlify="true"
              netlify-honeypot="bot-field"
            >
              <div className="page-title-overlap bg-accent pt-2">
                <div className="container d-lg-flex justify-content-between py-4 py-lg-3">
                  <div className="pl-lg-2 order-lg-2 mb-0 mb-lg-0 pt-lg-2 breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center">
                    <p className="text-white">CHECKOUT</p>
                  </div>
                </div>
              </div>
              <section className="container p-0 p-sm-4">
                <div className="row no-gutters py-md-1 py-lg-4 px-2 px-sm-2 px-md-3 px-lg-4">
                  <div className="col-xl-6 mb-3 bg-light box-shadow-lg rounded-lg overflow-hidden px-3 py-3 p-sm-3">
                    <h5 className="border-bottom text-center">Lieferadresse</h5>
                    {showAutoComplete || sForm["address"] === "" ? (
                      <div className="addressContainer">
                        <PlacesAutocomplete
                          onChange={(value) => handleAutoCompleteChange(value)}
                          placeholder={sForm["address"]}
                        />
                        <div className="mt-4 alert alert-primary">
                          Bitte verrate uns Deine Adresse, um die Liefergebühr
                          zu berechnen
                        </div>
                      </div>
                    ) : (
                      <div className=" d-flex flex-column align-items-center justify-content-center">
                        <Address
                          showAutoComplete={() => setShowAutoComplete(true)}
                          sForm={sForm}
                        />
                        {/* Stock tur stiege */}
                        <div
                          className="d-flex justify-content-between my-2 mx-0"
                          style={{ maxWidth: 500 + "px" }}
                          id="moredetails"
                        >
                          <div className="form-group">
                            <label className="hidden-lbl" htmlFor="stiege">
                              Stiege
                            </label>
                            <Field
                              name="stiege"
                              className={[
                                formik.submitCount > 0 && formik.errors.stiege
                                  ? "form-control frmctrl is-invalid"
                                  : "form-control frmctrl ",
                              ]}
                              //onBlur={handleBlur}
                              // onChange={(e) => onChange(e)}
                              placeholder="Stiege"
                              type="text"
                              onKeyPress={(e) => {
                                if (e.key === "Enter") e.preventDefault();
                              }}
                            />
                            {formik.submitCount > 0 && formik.errors.stiege ? (
                              <div className="invalid-feedback">
                                {formik.errors.stiege}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group mx-4">
                            <label className="hidden-lbl" htmlFor="stock">
                              Stock
                            </label>
                            <Field
                              name="stock"
                              className={[
                                formik.submitCount > 0 && formik.errors.stock
                                  ? "form-control frmctrl is-invalid"
                                  : "form-control frmctrl ",
                              ]}
                              placeholder="Stock"
                              type="text"
                              onKeyPress={(e) => {
                                if (e.key === "Enter") e.preventDefault();
                              }}
                            />
                            {formik.submitCount > 0 && formik.errors.stock ? (
                              <div className="invalid-feedback">
                                {formik.errors.stock}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <label className="hidden-lbl" htmlFor="tur">
                              Tür
                            </label>
                            <Field
                              name="tur"
                              className={[
                                formik.errors.tur
                                  ? "form-control frmctrl is-invalid"
                                  : "form-control frmctrl ",
                              ]}
                              placeholder="Tür"
                              type="text"
                              onKeyPress={(e) => {
                                if (e.key === "Enter") e.preventDefault();
                              }}
                            />
                            {formik.errors.tur ? (
                              <div className="invalid-feedback">
                                {formik.errors.tur}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-xl-5 px-sm-2 offset-xl-1 ">
                    <Cart
                      single={checkout["single"]}
                      double={checkout["double"]}
                    />
                    <div className="d-flex flex-column justify-content-between mb-4">
                      <div className="bg-light rounded-lg py-2 mb-2 px-2 px-sm-5 box-shadow ">
                        <h5 className="my-3 text-center">
                          Hast Du einen Gutschein-Code?{" "}
                        </h5>
                        <div
                          className="form-group mx-auto w-auto"
                          style={{ maxWidth: "250px" }}
                        >
                          <label className="hidden-lbl" htmlFor="codepromo">
                            Code Promo
                          </label>
                          <Field
                            name="codepromo"
                            className={"form-control frmctrl"}
                            placeholder="PROMO CODE"
                            type="text"
                            onKeyPress={(e) => {
                              if (e.key === "Enter") e.preventDefault();
                            }}
                            // onChange={(e) => checkCode(e)}
                          />
                          {formik.errors.codepromo ? (
                            <div className="invalid-feedback">
                              {formik.errors.codepromo}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <button
                        disabled={totalCart <= 0}
                        type="submit"
                        className="btn btn-primary btn-shadow btn-block mx-auto"
                        aria-label="Checkout"
                      >
                        <span className="czi-cart mr-2" aria-hidden="false" />
                        Checkout
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
}

export default Checkout01;
